import React, { useMemo } from 'react'
import { PostDisplayType, PostType } from '@/services/post-service/types'
import ContentDisplay from './ContentDisplay'
import CommentDisplay from './CommentDisplay'
import { useNavigate } from 'react-router-dom'


type PostDisplayProps = {
  post: PostDisplayType
}

const PostDisplay = ({ post }: PostDisplayProps) => {
  const navigate = useNavigate()

  return (
    <div
      className='cursor-pointer hover:bg-slate-50/70 bg-white rounded-[12px] mb-2'
      onClick={() => {
        navigate(`/post/${post.postId}`)
      }}
    >
      <div className={`pt-3`}>
        <ContentDisplay
          types='post'
          content={post}
          isLine={post.comments && post.comments.length > 0}
        />
      </div>
      <div>
        {post.previews &&
          post.previews.map((comment, index) => {
            return (
              <CommentDisplay
                key={comment.commentId}
                comment={comment}
                isLine={index + 1 != post.previews?.length}
              />
            )
          })}
      </div>
      {post.comments && post.comments.length > 2 && (
        <div className='px-4 text-slate-400/80 text-[14px] pb-2 mt-[-4px]'>
          {post.comments.length - 2} more comments...
        </div>
      )}
    </div>
  )
}

export default React.memo(PostDisplay)
