import { useInfiniteQuery } from 'react-query'
import { returnSupabase, supabase } from '../services/supabase'
import snakeCase from 'lodash.snakecase'
import { PostColumns, PostDisplayType } from '../services/post-service/types'
import { useMemo } from 'react'

export const mainPostQueryKey = 'postss'
const limitLength = 10

export function usePosts(column: PostColumns, value: string) {

  //console.log("usePosts 실행중....")
  //console.log(snakeCase(column))
  //console.log(value)

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery<PostDisplayType[]>({
    queryKey: [mainPostQueryKey],
    queryFn: async ({ pageParam = 1 }) => {
      const { data, error } = await supabase
        .from('post')
        .select(
          `
          post_id,
          created_at,
          content,
          user_id,
          image_url,
          user (user_id, name, profile_image_url),
          previews:comment (*, user (user_id, name, profile_image_url)),
          comments:comment (comment_id),
          created_by,
          is_encrypted
          `
        )
        .order('created_at', { ascending: false })
        .or(`created_by.eq.ai,${snakeCase(column)}.eq.${value}`)
        .filter('status', 'is', null)
        .filter('previews.parent_comment_id', 'is', null)
        .filter('previews.status', 'is', null)
        .filter('comments.status', 'is', null)
        .or(`created_by.eq.ai,user_id.eq.${value}`, { foreignTable: 'previews' })
        .or(`created_by.eq.ai,user_id.eq.${value}`, { foreignTable: 'comments' })
        .limit(2, { foreignTable: 'previews' })
        .range((pageParam - 1) * limitLength, pageParam * limitLength - 1)

      if (error) {
        throw new Error(error.message)
      }
      //console.log(data)
      return returnSupabase(data, error)
    },
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length + 1

      //상품이 0개이거나 rowsPerPage보다 작을 경우 마지막 페이지로 인식한다.
      return lastPage?.length === 0 || lastPage?.length < limitLength
        ? undefined
        : nextPage
    },
    staleTime: 1000 * 30 * 1, // 5분 동안 데이터를 "fresh" 상태로 유지
    refetchOnWindowFocus: false, // 윈도우 포커스 변경 시 다시 fetch하지 않음
    enabled: true,
    refetchOnReconnect: false,
  })

  const posts = useMemo(() => {
    if (data) return data.pages.flat()
    else return []
  }, [data])

  return {
    posts,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  }
}

export function useOnlyPosts(column: PostColumns, value: string) {

  console.log("useOnlyPosts 실행 중...")

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery<PostDisplayType[]>({
    queryKey: ['user_profile_posts'],
    queryFn: async ({ pageParam = 1 }) => {
      const { data, error } = await supabase
        .from('post')
        .select(
          `
          post_id,
          created_at,
          content,
          user_id,
          user (user_id, name, profile_image_url),
          comments:comment (comment_id),
          is_encrypted
          `,
          { count: 'exact' }
        )
        .order('created_at', { ascending: false })
        .eq(snakeCase(column), value)
        // .filter('comments.parent_comment_id', 'is', null)
        .filter('status', 'is', null)
        .range((pageParam - 1) * limitLength, pageParam * limitLength - 1)

      if (error) {
        throw new Error(error.message)
      }

      return returnSupabase(data, error)
    },
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length + 1
      return lastPage?.length === 0 || lastPage?.length < limitLength
        ? undefined
        : nextPage
    },
    enabled: true,
    staleTime: 1000 * 10 * 1, // 5분 동안 데이터를 "fresh" 상태로 유지
  })

  const posts = useMemo(() => {
    if (data) return data.pages.flat()
    else return []
  }, [data])

  return {
    posts,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  }
}
