import React from 'react'
import { PostDisplayType, PostType } from '@/services/post-service/types'
import PostDisplay from '../organisms/PostDisplay'
import {
  formatDate,
  formatDateMMDDYY,
  isSameDay,
  isToday,
} from '../../utils/formatDate'
import { decryptData } from '../../utils/crypto';

type MainPostListProps = {
  posts: PostDisplayType[] | undefined
}

const MainPostList = ({ posts }: MainPostListProps) => {
  return (
    <div>
      {posts && posts.length > 0 && isToday(new Date(posts[0].createdAt)) && (
        <div className='mt-2 mb-2 font-bold text-[2.4em] px-4'>Today</div>
      )}
      {posts && posts.length > 0 && !isToday(new Date(posts[0].createdAt)) && (
        <div className='text-gray-400 mt-1 text-[14px] px-4'>
          {formatDateMMDDYY(new Date(posts[0].createdAt))}
        </div>
      )}
      {posts &&
        posts.map((post, index) => {

          return (
            <>
              {index > 0 &&
                !isSameDay(
                  new Date(posts[index - 1].createdAt),
                  new Date(post.createdAt)
                ) && (
                  <div className='text-gray-400 mt-4 text-[14px] px-4'>
                    {formatDateMMDDYY(new Date(post.createdAt))}
                  </div>
                )}
              <PostDisplay key={post.postId} post={post} />
              {/* {posts.length != 0 && (
                <div
                  key={post.postId}
                  className='border-b-[0.5px] border-b-slate-300'
                ></div>
              )} */}
            </>
          )
        })}
    </div>
  )
}

export default React.memo(MainPostList)
